<template>
    <thead>
        <tr>
            <th
                v-for="(i, idx) in labels"
                :key="i.label"
                class="whitespace-nowrap"
                :class="{ 'cursor-pointer': i.isSort }"
                @click="changeSort(idx, i)">
                {{ i.label }}
                <div v-if="i.isSort" class="w-[20px] inline-block">
                    <span v-if="orderByOption.orderBy == '' || orderByOption.sortBy !== i.val">
                        <i class="icofont-long-arrow-up text-lg text-gray-800"></i>
                        <i class="icofont-long-arrow-down text-lg py-1 text-gray-800 -ml-3"></i>
                    </span>
                    <i
                        v-if="orderByOption.orderBy == 'asc' && orderByOption.sortBy == i.val"
                        class="icofont-long-arrow-up text-orange-600 mr-1 text-lg py-1"></i>
                    <i
                        v-if="orderByOption.orderBy == 'desc' && orderByOption.sortBy == i.val"
                        class="icofont-long-arrow-down text-orange-600 text-lg py-1"></i>
                </div>
            </th>
        </tr>
    </thead>
</template>

<script>
export default {
    name: "TableHead",
    props: {
        labels: {
            type: Array,
            require: false,
            default() {
                return [
                    //  {
                    //     label: "",
                    //     isSort: true,
                    //     val: "",
                    // },
                ];
            },
        },
    },
    data() {
        return {
            orderLabels: [],
            orderByOption: {
                sortBy: null,
                orderBy: "",
            },
        };
    },
    methods: {
        changeSort(idx, item) {
            // 若 isSort 沒設定則不做任何動作
            if (!item.isSort) {
                return;
            }

            // 點擊另一個 sortBy 時
            if (item.val !== this.orderByOption.sortBy) {
                this.orderLabels[idx].orderBy = "desc";
                this.orderByOption.orderBy = "desc";
                this.orderByOption.sortBy = item.val;
                this.$emit("changeSort", this.orderByOption);
                return;
            }

            this.orderByOption.sortBy = item.val;

            // 轉換下一個 orderBy
            if (!item.orderBy) {
                this.orderLabels[idx].orderBy = "desc";
                this.orderByOption.orderBy = "desc";
            } else if (item.orderBy === "desc") {
                this.orderLabels[idx].orderBy = "asc";
                this.orderByOption.orderBy = "asc";
            } else {
                this.orderLabels[idx].orderBy = "";
                this.orderByOption.orderBy = "";
                this.orderByOption.sortBy = "";
            }

            this.$emit("changeSort", this.orderByOption);
        },
        // 重新設定
        reset() {
            this.orderByOption = {
                sortBy: null,
                orderBy: "",
            };
        },
    },
    mounted() {
        this.orderLabels = [...this.labels];
    },
};
</script>

<style></style>
